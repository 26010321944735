<template>
  <div>
    <div class="selection">
      <img
        ref="mainImage"
        :src="getMainImage()"
        onerror="this.src='https://media.kaiser.fyi/mindsupport/1920/peer_self_support.png'"
        v-if="myInnerWidth > 640"
        style="width: 100vw; height: auto"
        usemap="#linkmapDesktop"
      />
      <map name="linkmapDesktop">
        <!--        2412 * 899 <- Base Größe-->
        <!--        peer_self_support_complete_edit.png <- Datei-->
        <area
          target=""
          alt="Peer Support"
          title="Peer Support"
          href="support/peer"
          :coords="getImageArea([726, 277, 357])"
          shape="circle"
        />
        <area
          target=""
          alt="Self Support"
          title="Self Support"
          href="support/self"
          :coords="getImageArea([1691, 398, 262])"
          shape="circle"
        />
      </map>
      <!-- MUSS NOCH ANGEPASST WERDEN -->
      <img
        ref="mainImageMobile"
        src="../assets/landing/bg_mobile_crop.png"
        v-if="myInnerWidth <= 640"
        style="width: 100vw; height: auto"
        usemap="#linkmapMobile"
      />
      <map name="linkmapMobile">
        <!--        608 * 715 <- Base Größe-->
        <!--        bg_mobile_crop.png <- Datei-->
        <area
          target=""
          alt="Peer Support"
          title="Peer Support"
          href="support/peer"
          :coords="getImageAreaMobile([450, 103, 142])"
          shape="circle"
        />
        <area
          target=""
          alt="Self Support"
          title="Self Support"
          href="support/self"
          :coords="getImageAreaMobile([134, 572, 120])"
          shape="circle"
        />
      </map>
    </div>
    <div
      v-bind:class="{ team: !myWidth, teamMobile: myWidth }"
      id="Team"
      v-if="teamMembers.length"
    >
      <div class="header" id="teamHeader">
        {{ getContent(1321) }}
      </div>
      <carousel-3d
        :autoplay="true"
        :autoplay-timeout="30000"
        :autoplayHoverPause="true"
        :display="3"
        :width="400"
        :height="700"
        :inverse-scaling="400"
        :perspective="35"
        :space="800"
        :controls-visible="true"
        :controls-prev-html="'&#10092; '"
        :controls-next-html="'&#10093;'"
        :controls-width="30"
        :animationSpeed="1000"
        :border="0"
        style="
          margin: 50px 0 0 0;
          opacity: 0;
          transition: opacity 2s ease-in-out;
        "
        id="carousel"
      >
        <slide
          v-for="(member, i) in this.teamMembers"
          :index="i"
          v-bind:key="i"
          style="background: transparent"
        >
          <template slot-scope="{ index, isCurrent }">
            <div class="member-large" id="teamMembers">
              <div class="member-large-img" id="img-mid" v-if="member.img">
                <img
                  :src="member.img"
                  :style="'border-color:' + teamOptions[member.team]"
                />
              </div>
              <div
                class="member-large-img"
                id="img-mid"
                v-if="member.img == ''"
              >
                <img src="../assets/landing/account_circle_black_48dp.svg" />
              </div>
              <div class="member-large-name">
                {{ member.name }}
              </div>
              <div class="member-large-team">
                <h3
                  :style="'color:' + teamOptions[member.team]"
                  style="margin-top: 0"
                >
                  {{ member.teamDescription }}
                </h3>
              </div>
              <div
                :data-index="index"
                :class="{
                  'member-large-about-current': isCurrent,
                  'member-large-about-side': !isCurrent,
                }"
              >
                <p>{{ member.description }}</p>
              </div>
            </div>
          </template>
        </slide>
      </carousel-3d>
    </div>
    <div v-bind:class="{ about: !myWidth, aboutMobile: myWidth }">
      <div class="header" id="aboutHeader">
        {{ getContent(1322) }}
      </div>
      <div
        class="information"
        v-bind:class="{ flexRow: !myWidth, flexColumn: myWidth }"
        id="aboutInformation"
      >
        <div class="menu" id="aboutMenu">
          <ul style="list-style-type: none; margin: 0; padding: 0">
            <li
              @click="changeContent('0')"
              v-bind:class="{ focusOn: focus == 0 }"
            >
              <h3>{{ getContent(1323) }}</h3>
            </li>
            <li
              @click="changeContent('1')"
              v-bind:class="{ focusOn: focus == 1 }"
            >
              <h3>{{ getContent(1324) }}</h3>
            </li>
            <li
              @click="changeContent('2')"
              v-bind:class="{ focusOn: focus == 2 }"
            >
              <h3>{{ getContent(1325) }}</h3>
            </li>
            <li
              @click="changeContent('3')"
              v-bind:class="{ focusOn: focus == 3 }"
            >
              <h3>{{ getContent(1326) }}</h3>
            </li>
            <li
              @click="changeContent('4')"
              v-bind:class="{ focusOn: focus == 4 }"
            >
              <h3>{{ getContent(1327) }}</h3>
            </li>
            <li
              @click="changeContent('5')"
              v-bind:class="{ focusOn: focus == 5 }"
            >
              <h3>{{ getContent(1328) }}</h3>
            </li>
          </ul>
        </div>
        <div class="text" id="aboutText">
          <div class="TextFade" id="0">
            <p v-html="getContent(1314)"></p>
          </div>
          <div class="TextFade" id="1">
            <p v-html="getContent(1315)"></p>
          </div>
          <div class="TextFade" id="2">
            <p v-html="getContent(1316)"></p>
          </div>
          <div class="TextFade" id="3">
            <p v-html="getContent(1317)"></p>
          </div>
          <div class="TextFade" id="4">
            <p v-html="getContent(1318)"></p>
          </div>
          <div class="TextFade" id="5">
            <p v-html="getContent(1319)"></p>
          </div>
        </div>
      </div>
      <div id="About"></div>
    </div>

    <div v-bind:class="{ contactUs: !myWidth, contactUsMobile: myWidth }">
      <div class="header" id="contactUsHeader">
        {{ getContent(1363) }}
      </div>
      <div
        class="information"
        v-bind:class="{ flexrow: !myWidth, flexColumn: myWidth }"
        id="contactUsInformation"
      >
        <div class="text" id="contactUsText" v-html="getContent(1364)"></div>
      </div>
      <div class="peopleBottom" id="peopleBottom">
        <img src="https://media.kaiser.fyi/mindsupport/650/person1_2.png" />
      </div>
      <div id="contactUs"></div>
    </div>
  </div>
</template>

<script>
import { loadData } from "@/utils";
import { axiosGet } from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      myWidth: screen.height / screen.width > 1,
      myInnerWidth: window.innerWidth,
      elements: [
        "teamHeader",
        "teamMembers",
        "aboutHeader",
        "aboutInformation",
        "aboutMenu",
        "aboutText",
        "contactUsHeader",
        "contactUsInformation",
        "contactUsText",
        "peopleBottom",
        "carousel",
      ],

      mainImage: {
        w: 1,
        h: 1,
        hO: 899,
        wO: 2412,
      },
      mainImageMobile: {
        w: 1,
        h: 1,
        hO: 715,
        wO: 608,
      },
      contentAbout: "",
      focus: 0,
      text: {},
      teamOptions: {
        Management: "#a675a1",
        "Peer Support/Social Media": "#590925",
        "Written Content": "#03312e",
        "Self Support": "#7a6f9b",
        Design: "#119822",
        Development: "#9999A1",
        Funding: "#FFBC42",
      },
      teamMembers: [],
      teamMemberID: 500,
    };
  },
  methods: {
    getContent: function (key) {
      return this.text[key];
    },
    determineMobile() {
      this.myInnerWidth = window.innerWidth;
    },
    getMember: function (id) {
      return id % this.teamMembers.length;
    },
    getMemberLeft: function (id) {
      let temp = (id - 1) % this.teamMembers.length;
      if (temp < 0) {
        temp = this.teamMembers.length - 1;
      }
      return temp;
    },
    getMemberRight: function (id) {
      return (id + 1) % this.teamMembers.length;
    },
    getMainImage: function () {
      return `https://media.kaiser.fyi/mindsupport/1024/peer_self_support_${this.myLanguage}.png`;
    },
    getImageArea: function (c) {
      return c.map((x) => x * this.mainImage.w).join(",");
    },
    getImageAreaMobile: function (c) {
      return c.map((x) => x * this.mainImageMobile.w).join(",");
    },
    calcImageMap() {
      if (this.myInnerWidth > 640) {
        this.mainImage.w = this.$refs.mainImage.width / this.mainImage.wO;
        this.mainImage.h = this.$refs.mainImage.height / this.mainImage.hO;
      } else {
        this.mainImageMobile.w =
          this.$refs.mainImageMobile.width / this.mainImageMobile.wO;
        this.mainImageMobile.h =
          this.$refs.mainImageMobile.height / this.mainImageMobile.hO;
      }
    },
    scroll: function (div) {
      document
        .getElementById(div.substring(1))
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    changeContent: function (id) {
      if (id != this.focus) {
        const elem0 = document.getElementById(this.focus);
        const elem1 = document.getElementById(id);
        elem0.style.opacity = "0";
        setTimeout(() => {
          elem0.style.display = "none";
        }, 500);
        setTimeout(() => {
          elem1.style.display = "inherit";
        }, 500);
        setTimeout(() => {
          elem1.style.opacity = "1";
        }, 520);
        setTimeout(() => {
          this.focus = id;
        }, 500);
      }
    },
    isElementInViewport: function (element) {
      let rect = element.getBoundingClientRect();
      return rect.top <= window.innerHeight;
    },
    callbackFunc: function () {
      for (let i = 0; i < this.elements.length; i++) {
        // console.log(this.elements);
        if (
          this.isElementInViewport(document.getElementById(this.elements[i]))
        ) {
          document.getElementById(this.elements[i]).style.opacity = 1;
        }
        // } else {
        //   /* Else-Bedinung entfernen, um .visible nicht wieder zu löschen, wenn das Element den Viewport verlässt. */
        //   // this.elements[i].style.opacity = 0;
        // }
      }
    },
  },
  async beforeMount() {
    this.teamMembers = (await axiosGet("/team/published/")).data.sort(
      (a, b) => {
        if (b.importance - a.importance !== 0)
          return b.importance - a.importance;
        else if (("" + a.team).localeCompare(b.team) !== 0)
          return ("" + a.team).localeCompare(b.team);
        else return ("" + a.name).localeCompare(b.name);
      }
    );
  },
  async created() {
    this.text = await loadData("LandingPage", this.myLanguage);
    this.contentAbout = this.getContent(1314);

    window.addEventListener("resize", this.determineMobile);

    // Map
    this.calcImageMap();
    window.addEventListener("resize", this.calcImageMap);
    document.getElementById(this.focus).style.display = "inherit";
    document.getElementById(this.focus).style.opacity = "1";

    window.addEventListener("scroll", this.callbackFunc);
    window.addEventListener("load", this.callbackFunc);
    this.callbackFunc;
  },
  destroyed() {
    window.removeEventListener("resize", this.calcImageMap);
    window.removeEventListener("scroll", this.callbackFunc);
    window.removeEventListener("load", this.callbackFunc);
    window.removeEventListener("resize", this.determineMobile);
  },
  mounted() {
    const anchor = this.$router.currentRoute.hash;
    if (anchor) {
      try {
        this.scroll(anchor);
      } catch (e) {
        console.warn(e);
      }
    }
  },

  name: "LandingPage",
};
</script>

<style scoped>
html,
p {
  font-family: var(--text);
}
.selection {
  background: linear-gradient(0deg, var(--c2), #ffffff);
  /* background: var(--c1); */
  padding: 2% 0;
  /* opacity: 0; */
}
.team,
.teamMobile {
  /* background: linear-gradient(180deg, #dfab79, #ffffff); */
  background: var(--c2);
}
.about,
.aboutMobile {
  background: linear-gradient(180deg, var(--c2), #dfab79);
  /* background: var(--c1); */
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
}
.aboutMobile {
  height: 1000px;
}
.contactUs,
.contactUsMobile {
  background: var(--c1);
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
}
.aboutMobile {
  height: 1000px;
}
.team .header {
  font-family: var(--h1);
  font-size: 7em;
  color: #6e502e;
  text-shadow: 0px 2px 2px #a07f59;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 6vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.teamMobile .header {
  font-family: var(--h1);
  font-size: 4em;
  color: #6e502e;
  text-shadow: 0px 2px 2px #a07f59;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 8vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.team .members {
  padding-top: 5vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.teamMobile .members {
  padding-top: 5vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.team .member-large,
.teamMobile .member-large {
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team .member-large-img img,
.teamMobile .member-large-img img {
  width: 200px;
  max-width: 40vw;
  height: auto;
  margin: auto;
  border-radius: 50%;
  border: 10px solid #123;
}
.team .member-large-name,
.teamMobile .member-large-name {
  color: #51638b;
  font-family: var(--h1);
  /*font-size: 1.4em;*/
  /*white-space: nowrap;*/
  text-align: center;
  font-size: 3.5em;
  line-height: 1.8em;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.teamMobile .member-large-name {
  font-size: 3em;
}
.team .member-large-team,
.teamMobile .member-large-team {
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 0.5em;
  text-transform: uppercase;
  text-align: center;
}
.team .member-large-about-current,
.teamMobile .member-large-about-current {
  width: 100%;
  text-align: justify;
  transition: opacity 1s;
}
.teamMobile .member-large-about-current {
  width: 90%;
}
.team .member-large-about-side,
.teamMobile .member-large-about-side {
  width: 100%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  transition: opacity 1s;
}
.teamMobile .member-large-about-side {
  width: 90%;
}
.team .mybutton {
  margin-top: 12vh;
  width: 5%;
}
.teamMobile .mybutton {
  margin-top: 12vh;
  width: 5%;
}
.about .header,
.aboutMobile .header {
  font-family: var(--h1);
  font-size: 7em;
  color: #51638b;
  text-shadow: 0px 2px 2px #7991c5;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.aboutMobile .header {
  font-size: 4em;
}
.about .information,
.aboutMobile .information {
  padding-top: 5vw;
  max-width: 960px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.about .information .menu {
  width: 33%;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.2em;
  line-height: 1.5vw;
  color: #4e647c;
  cursor: pointer;
  font-weight: bold;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.aboutMobile .information .menu {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2em;
  line-height: 1.5em;
  color: #4e647c;
  cursor: pointer;
  font-weight: bold;
  padding-top: 1em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.about .information .text {
  width: 66%;
  /*padding-right: 10vw;*/
  padding-left: 5vw;
  text-align: left;
  font-size: 1em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.about .information .text .TextFade {
  display: none;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  transition: opacity 0.5s;
}
.aboutMobile .information .text {
  text-align: left;
  font-size: 1em;
  padding: 2em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.aboutMobile .information .text .TextFade {
  display: none;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  transition: opacity 0.5s;
}
.contactUs .header,
.contactUsMobile .header {
  font-family: var(--h1);
  font-size: 7em;
  color: #51638b;
  text-shadow: 0px 2px 2px #7991c5;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3vh;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.contactUsMobile .header {
  font-size: 4em;
}
.contactUs .information,
.contactUsMobile .information {
  padding-top: 5vw;
  max-width: 960px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.contactUs .information .text {
  /* width: 66%;
  padding-right: 10vw;
  padding-left: 5vw;*/
  text-align: justify;
  font-size: 1em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.contactUsMobile .information .text {
  text-align: justify;
  font-size: 1em;
  padding: 2em;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.focusOn {
  text-decoration: underline;
  filter: brightness(130%);
}
@keyframes peopleBottom {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}
.contactUs .peopleBottom {
  flex: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.contactUs .peopleBottom img {
  display: flex;
  /* width: 33%; */
  /* disabled since using media.kaiser.fyi for scaling */
  /* z-index: 0; */
  /* Wenn man Insel leicht hinter Footer floaten lassen möchte */
  animation-name: peopleBottom;
  animation-iteration-count: infinite;
  animation-duration: 10s;
}
.contactUsMobile .peopleBottom {
  flex: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.contactUsMobile .peopleBottom img {
  display: flex;
  width: 66%;
  animation-name: peopleBottom;
  animation-iteration-count: infinite;
  animation-duration: 10s;
}
</style>
